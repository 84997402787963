<template>
  <b-card-body>
    <template v-if="!choose_phone_number">
      <b-card-title>Enable Conversion API</b-card-title>
      <b-card-sub-title>Facebook Messenger is one of the most popular messenger in the world and used by millions of Facebook user. Enable this channel allows you to reach your customer that come from your Facebook Page..</b-card-sub-title>
        <el-divider/>
      <b-card-sub-title class="font-weight-bold">Before you start, you'll need:</b-card-sub-title>
      <ol>
        <li>a Facebook Account</li>
        <li>A Facebook Page connected to that account</li>
      </ol>
      <el-button :loading="loading.embed_signup" @click="launchWhatsAppSignup()" style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;">Log in with Facebook</el-button>
    </template>
  </b-card-body>
</template>
<script>
import { AsYouType } from 'libphonenumber-js';
// import CountryFlag from 'vue-country-flag';
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  components: {
    // CountryFlag,
  },
  name: 'AddFacebookMessenger',
  data() {
    return {
      provider: 'client_premise',
      hasRegisteredAccount: null,
      whatsappProvider: [
        // { text: 'Makna.ai', value: 'makna' },
        // { text: 'DAMCorp', value: 'damcorp' },
        { text: 'On Premise', value: 'client_premise' },
        { text: 'Cloud API', value: 'cloud_api_wa' },
      ],
      hasRegistered: [
        { text: 'Yes, I have one', value: true },
        { text: 'No', value: false },
      ],
      configuration: {
        username: 'admin',
        password: '',
        base_url: '',
        token: '',
        business_id: '',
        phone_number_id: '',
      },
      loading: {
        embed_signup: false,
      },
      choose_phone_number: false,
      phone_number_list: [],
    };
  },
  methods: {
    getCountryCode(input) {
      const asYouType = new AsYouType();
      asYouType.input(input);
      return asYouType.getNumber().country.toLowerCase();
    },
    createChannel() {
      this.$emit('createChannel', this.configuration, this.provider);
    },
    /* eslint-disable no-undef */
    async launchWhatsAppSignup() {
      try {
        this.loading.embed_signup = true;
        FB.login((response) => {
          if (response.authResponse) {
            console.log(response);
            // Use this token to call the debug_token API and get the shared WABA's ID
            const { accessToken } = response.authResponse;
            channelsAPI.initLinkAgeFacebookMessenger({
              token: accessToken,
            }).then(async (response1) => {
              await popupErrorMessages(response1);
              this.configuration = response1.data;
              this.provider = 'facebook_direct';
              this.createChannel();
              // const { data } = await channelsAPI.getEmbedPhoneNumber();
              // this.phone_number_list = data.list;
              // this.choose_phone_number = true;
              // this.provider = 'cloud_api_embed';
            });
          } else {
            console.log('User cancelled login or did not fully authorize.', response);
          }
          this.loading.embed_signup = false;
        }, {
          // scope: 'pages_show_list,pages_read_engagement,pages_manage_metadata,pages_messaging',
          // extras: {
          //   // feature: 'whatsapp_embedded_signup',
          //   setup: {
          //     channel: 'FB_API_ONBOARDING',
          //   },
          // },
          config_id: '486345977474397',
          response_type: 'code',
          override_default_response_type: true,
        });
      } catch (error) {
        console.log(error);
        this.loading.embed_signup = false;
      }
    },
  },
};
</script>
