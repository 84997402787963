<template>
  <b-card-body>
    <template v-if="!ready_scan_qr">
      <b-card-title>WhatsApp Personal</b-card-title>
      <b-card-sub-title>WhatsApp is one of the most popular communication applications in the world. You will linkage your WhatsApp to our platform. Please be aware for WhatsApp limitation up to 4 devices per account. Please remove one or more if you already reach the limitation.</b-card-sub-title>
      <b-card-sub-title>Prepare your device to scan QR code that will displayed on this page. Continue?</b-card-sub-title>
      <b-form-group>
        <el-button @click="initQR" type="primary" :loading="loading.initialize">{{ loading.initialize ? 'Processing. Please wait...' : 'Continue' }}</el-button>
      </b-form-group>
    </template>
    <template v-if="ready_scan_qr">
      <b-card-title>To link Makna with you WhatsApp Account</b-card-title>
      <div class="row">
        <div class="col-6">
          <ol class="mt-2">
            <li>Open WhatsApp on your phone</li>
            <li>Tap Settings and select <strong>Linked Account</strong></li>
            <li>Tap on <strong>Link a Device</strong></li>
            <li>Point your phone to this screen to capture the code</li>
          </ol>
        </div>
        <div class="col-4">
          <img :src="qrcode_uri"/>
          <div class="items-center" v-if="!qrcode_uri" align="center">
            <el-skeleton :loading="!qrcode_uri" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="image"
                  style="width: 240px; height: 240px;"
                />
              </template>
            </el-skeleton>
            <span class="font-weight-bold text-center">Please wait, this might take a while...</span>
          </div>
        </div>
      </div>
    </template>
    <!-- <template v-else>
      <b-card-title>Select Phone Number</b-card-title>
      <b-card-sub-title>Please select one of phone number below to be added to this workspace.</b-card-sub-title>
      <div v-for="(bus, index1) in phone_number_list" :key="index1">
        <p class="mb-2"><strong>{{ bus.business.name }}</strong><br>WABA ID {{ bus.business.id }}</p>
        <el-radio-group v-model="configuration.phone_number_id">
          <el-radio-button v-for="(phone, index2) in bus.phone_numbers" :key="index2" :label="phone.id" border>
            <span><country-flag :country="getCountryCode(phone.display_phone_number)" size='small'/>&nbsp;{{ phone.display_phone_number }}</span>
          </el-radio-button>
        </el-radio-group>
        <el-divider/>
      </div>
      <b-button @click="createChannel" variant="primary">{{ $t('general.submit') }}</b-button>
    </template> -->
  </b-card-body>
</template>
<script>
import openSocket from 'socket.io-client';
import config from '@/config';
import channelsAPI from '../../../api/channels';
// import popupErrorMessages from '../../../library/popup-error-messages';

// const qrcode = require('qrcode');

const socket = openSocket(config.api.baseUrl, {
  transports: ['websocket'],
  upgrade: false,
  reconnection: true,
  reconnectionAttempts: 1000,
  reconnectionDelay: 1000,
});

export default {
  components: {
  },
  name: 'AddWhatsappPersonal',
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  data() {
    return {
      provider: 'whatsapp',
      configuration: {},
      loading: {
        initialize: false,
      },
      ready_scan_qr: false,
      phone_number_list: [],
      reconnect: true,
      qrcode_uri: '',
      is_connected: false,
    };
  },
  beforeDestroy() {
    this.reconnect = false;
    socket.disconnect();
  },
  mounted() {
    this.doSocketConnection();
    socket.on('connect', () => {
      console.log('connected to socket');
    });
    socket.on('qrcode:whatsapp-personal', async (data) => {
      const { status, qr } = data;
      if (status === 'waiting_connection') {
        this.qrcode_uri = qr; // await qrcode.toDataURL(qr, { version: 3 });
      } else {
        console.log(status);
        if (status === 'connected') this.addChannel();
      }
    });
  },
  methods: {
    doSocketConnection() {
      socket.connect();
      socket.emit('qrcode:whatsapp-personal', this.activeWorkspace._id);
    },
    async initQR() {
      this.loading.initialize = true;
      await channelsAPI.initializeChannel({
        workspace_id: this.activeWorkspace._id,
        channel_type: 'whatsapp_personal',
      });
      this.loading.initialize = false;
      this.ready_scan_qr = true;
    },
    async addChannel() {
      if (this.is_connected) {
        return;
      }
      this.is_connected = true;
      this.$emit('createChannel', this.configuration, this.provider);
    },
  },
};
</script>
